// @ts-check

import { createContext, useContext, useMemo } from 'react';

export const ParticipantSourceType = /** @type {const} */({
	CONFIG: 'config',
	AUDIOSHARE: 'audio',
	IMAGESHARE: 'image',
	VIDEOSHARE: 'video',
	SCREENSHARE: 'screen',
});

/**
 * @typedef {typeof ParticipantSourceType[keyof typeof ParticipantSourceType]} ParticipantSourceType
 */

/**
 * @typedef {'audioinput' | 'videoinput'} ParticipantSourceMediaDeviceKind
 * @typedef {'audioscreen' | 'videoscreen'} ParticipantSourceScreenDeviceKind
 * @typedef {'audiofile' | 'videofile'} ParticipantSourceFileDeviceKind
 * @typedef {ParticipantSourceMediaDeviceKind
 * | ParticipantSourceScreenDeviceKind
 * | ParticipantSourceFileDeviceKind} ParticipantSourceDeviceKind
 */

/**
 * @template {ParticipantSourceType} [SourceType=ParticipantSourceType]
 * @typedef {SourceType extends typeof ParticipantSourceType.CONFIG
 * 	? ParticipantSourceMediaDeviceKind
 * 	: SourceType extends typeof ParticipantSourceType.SCREENSHARE
 * 		? ParticipantSourceScreenDeviceKind
 * 		: SourceType extends (
 * 			typeof ParticipantSourceType.IMAGESHARE
 * 			| typeof ParticipantSourceType.VIDEOSHARE
 * 		)
 * 			? ParticipantSourceFileDeviceKind
 * 			: ParticipantSourceDeviceKind} ParticipantSourceDeviceKindFromType
 */

/**
 * @template {ParticipantSourceType} [SourceType=ParticipantSourceType]
 * @typedef {{
 *  deviceId: string,
 *  kind: ParticipantSourceDeviceKindFromType<SourceType>,
 *  label: string,
 * }} ParticipantSourceDevice;
 */

/**
 * @template {ParticipantSourceType} [SourceType=ParticipantSourceType]
 * @typedef {MediaStreamTrack & {
 *  configId?: number,
 *  device: ParticipantSourceDevice<SourceType>,
 *  sourceType: SourceType,
 * }} ParticipantSourceTrack
 */

/**
 * @template {ParticipantSourceType} [SourceType=ParticipantSourceType]
 * @typedef {{
 *  configId?: number,
 *  id: string,
 *  label: string,
 *  type: SourceType,
 *  devices: ParticipantSourceDevice<SourceType>[],
 * }} ParticipantSource
 */

/**
 * @typedef {{
 *  createOrUpdateSource: (source: ParticipantSource) => void
 *  removeSourceById: (sourceId: string) => void
 *  sources: ParticipantSource[],
 * }} IParticipantSourcesContext
 */

export const ParticipantSourcesContext = createContext(
	/** @type {IParticipantSourcesContext | undefined} */(undefined),
);

export const useParticipantSources = () => {
	const sourcesContext = useContext(ParticipantSourcesContext);
	// type guard (removes undefined type)
	if (!sourcesContext) {
		throw new Error('useParticipantSources must be used within a ParticipantSources');
	}
	return sourcesContext;
};

/**
 * @param {ParticipantSourceType} participantSourceType
 * @returns {ParticipantSource[]}
 */
export const useFilteredParticipantSources = (participantSourceType) => {
	const { sources } = useParticipantSources();
	return useMemo(
		() => sources.filter(({ type }) => (type === participantSourceType)),
		[participantSourceType, sources],
	);
};
