/* eslint-disable react/prop-types */
// @ts-check
import { createContext, useContext, useMemo } from 'react';

/** @import { AuthenticatedUser } from './ReactVideo'; */

/**
 * @typedef {{
 * 	getConnectionConfig: () => Promise<any>, // TODO: type
 * 	isController: boolean,
 * 	user: AuthenticatedUser,
 * }} IReactVideoContext
 */

export const ReactVideoContext = createContext(/** @type {IReactVideoContext} */({}));

export const useReactVideo = () => useContext(ReactVideoContext);

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * 	getConnectionConfig: () => Promise<any>, // TODO: type
 * 	isController?: boolean,
 * 	user: AuthenticatedUser,
 * }} ReactVideoProviderProps
 */

/** @type {React.FC<ReactVideoProviderProps>} */
export const ReactVideoProvider = ({
	children,
	getConnectionConfig,
	isController = false,
	user,
}) => {
	const contextValue = useMemo(() => ({
		getConnectionConfig,
		isController,
		user,
	}), [
		getConnectionConfig,
		isController,
		user,
	]);

	return (
		<ReactVideoContext.Provider value={contextValue}>
			{children}
		</ReactVideoContext.Provider>
	);
};
