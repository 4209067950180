// @ts-check

import { v4 as uuid } from 'uuid';

/**
 * @import { InputDeviceInfo, VirtualDevice } from './Context';
 */

const LOCAL_STORAGE_VIRTUAL_DEVICES_KEY = 'beeyou_virtualDevices';
const LOCAL_STORAGE_VIRTUAL_DEVICES_VERSION = 1;

/**
 * @typedef {{
 *  virtualDevices: VirtualDevice[],
 *  __version: number,
 * }} LocalStorageVirtualDevices
 */

/**
 * @returns {VirtualDevice[] | undefined}
 */
const getLocalStorageValue = () => {
	const value = localStorage.getItem(LOCAL_STORAGE_VIRTUAL_DEVICES_KEY);
	if (!value) {
		return undefined;
	}
	try {
		/** @type {LocalStorageVirtualDevices} */
		const parsedValue = JSON.parse(value);
		// eslint-disable-next-line no-underscore-dangle
		if (parsedValue.__version !== LOCAL_STORAGE_VIRTUAL_DEVICES_VERSION) {
			return undefined;
		}
		return parsedValue.virtualDevices;
	} catch (error) {
		console.error('Error parsing local storage value', error);
		return undefined;
	}
};

/**
 * @param {VirtualDevice[]} virtualDevices
 * @returns {void}
 */
const saveLocalStorageValue = (virtualDevices) => {
	localStorage.setItem(
		LOCAL_STORAGE_VIRTUAL_DEVICES_KEY,
		JSON.stringify({
			virtualDevices,
			__version: LOCAL_STORAGE_VIRTUAL_DEVICES_VERSION,
		}),
	);
};

/**
 * @returns {VirtualDevice[]}
 */
const createInitialVirtualDevices = () => [
	{
		isDefault: true,
		kind: /** @type {MediaDeviceKind}*/('videoinput'),
		label: 'Default Camera',
		physicalDeviceId: undefined,
		virtualDeviceId: uuid(),
	},
	{
		isDefault: true,
		kind: /** @type {MediaDeviceKind}*/('audioinput'),
		label: 'Default Microphone',
		physicalDeviceId: undefined,
		virtualDeviceId: uuid(),
	},
];

/**
 * @returns {VirtualDevice[]}
 */
const initializeVirtualDevices = () => {
	/** @type {VirtualDevice[] | undefined} */
	let initialVirtualDevices = getLocalStorageValue();
	if (!initialVirtualDevices) {
		initialVirtualDevices = createInitialVirtualDevices();
		saveLocalStorageValue(initialVirtualDevices);
	}
	return initialVirtualDevices;
};

/** @type {VirtualDevice[]} */
let virtualDevices = initializeVirtualDevices();

/**
 * @param {InputDeviceInfo} physicalDevice
 * @returns {VirtualDevice}
 */
const createVirtualDevice = (physicalDevice) => {
	if (!physicalDevice.deviceId) {
		throw new Error('Cannot create virtual device without physical device ID');
	}

	const virtualDevice = {
		kind: physicalDevice.kind,
		isBack: physicalDevice.isBack,
		isDefault: physicalDevice.isDefault,
		isFront: physicalDevice.isFront,
		label: physicalDevice.label,
		physicalDeviceId: physicalDevice.deviceId || undefined,
		virtualDeviceId: uuid(),
	};

	virtualDevices = [...virtualDevices, virtualDevice];
	saveLocalStorageValue(virtualDevices);

	return virtualDevice;
};

/**
 * @param {VirtualDevice} virtualDevice
 * @param {InputDeviceInfo} physicalDevice
 * @returns {VirtualDevice}
 */
const updateVirtualDevice = (virtualDevice, physicalDevice) => {
	if (
		virtualDevice.physicalDeviceId !== physicalDevice.deviceId
		|| virtualDevice.kind !== physicalDevice.kind
	) {
		throw new Error('Cannot update virtual device with different physical device');
	}

	virtualDevice = {
		...virtualDevice,
		isBack: physicalDevice.isBack,
		isDefault: physicalDevice.isDefault,
		isFront: physicalDevice.isFront,
		label: physicalDevice.label,
	};

	virtualDevices = virtualDevices.map((vd) => (
		(vd.physicalDeviceId === physicalDevice.deviceId
		&& vd.kind === physicalDevice.kind)
			? virtualDevice
			: vd
	));
	saveLocalStorageValue(virtualDevices);

	return virtualDevice;
};

/**
 * @returns {VirtualDevice[]}
 */
export const getVirtualDevices = () => virtualDevices;

/**
 * @param {InputDeviceInfo} physicalDevice
 * @returns {VirtualDevice}
 */
export const createOrUpdateVirtualDevice = (physicalDevice) => {
	let virtualDevice = virtualDevices.find((vd) => (
		physicalDevice.deviceId === vd.physicalDeviceId
		&& physicalDevice.kind === vd.kind
	));

	if (!virtualDevice) {
		virtualDevice = createVirtualDevice(physicalDevice);
	} else {
		virtualDevice = updateVirtualDevice(virtualDevice, physicalDevice);
	}

	return virtualDevice;
};

/**
 * @param {InputDeviceInfo} physicalDevice
 * @returns {VirtualDevice}
 */
export const updateDefaultVirtualDevice = (physicalDevice) => {
	if (!physicalDevice.isDefault) {
		throw new Error('Cannot update default virtual device with non-default physical device');
	}

	let virtualDevice = virtualDevices.find((vd) => (
		vd.isDefault
		&& physicalDevice.kind === vd.kind
	));

	if (!virtualDevice) {
		throw new Error(`Cannot find default virtual device of kind ${physicalDevice.kind}`);
	}

	virtualDevice = {
		...virtualDevice,
		isBack: physicalDevice.isBack,
		isFront: physicalDevice.isFront,
		label: physicalDevice.label,
		physicalDeviceId: physicalDevice.deviceId || undefined,
	};

	virtualDevices = virtualDevices.map((vd) => (
		(vd.isDefault === physicalDevice.isDefault
			&& vd.kind === physicalDevice.kind)
			? virtualDevice
			: vd
	));
	saveLocalStorageValue(virtualDevices);

	return virtualDevice;
};

/**
 * @param {InputDeviceInfo[]} physicalDevices
 * @returns {VirtualDevice[]}
 */
export const updateManyVirtualDevices = (physicalDevices) => {
	physicalDevices.forEach((physicalDevice) => {
		if (physicalDevice.isDefault) {
			updateDefaultVirtualDevice(physicalDevice);
		} else {
			createOrUpdateVirtualDevice(physicalDevice);
		}
	});
	return virtualDevices;
};

/**
 * @param {VirtualDevice['virtualDeviceId']} virtualDeviceId
 * @returns {VirtualDevice | undefined}
 */
export const getVirtualDeviceById = (virtualDeviceId) => (
	virtualDevices.find((vd) => vd.virtualDeviceId === virtualDeviceId)
);

/**
 * @param {MediaDeviceKind} kind
 * @returns {VirtualDevice | undefined}
 */
export const getDefaultVirtualDevice = (kind) => (
	virtualDevices.find((vd) => vd.isDefault && vd.kind === kind)
);
