/* eslint-disable react/prop-types */
// @ts-check
import {
	memo,
	createContext,
	useContext,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { useInputs } from '../Inputs/Context';
import {
	DEFAULT_AUDIO_CONSTRAINTS,
	Resolution,
	stopTrack,
	USER_MAX_FPS,
} from './utils';
import getUserMedia from '../../lib/getUserMedia';
import { getRollbarInstance } from '../../lib/rollbar';
import { useParticipantSources, ParticipantSourceType } from '../ParticipantSources/Context';

/**
 * @import {
 *  ParticipantSource,
 * 	ParticipantSourceMediaDeviceKind,
 *  ParticipantSourceTrack,
 * } from '../ParticipantSources/Context';
 */

/*
	My advice: don't touch it unless this is already the end of world.
	And if so, keep in mind that Safari won't fight on your side...
*/

const rollbar = getRollbarInstance();

/**
 * @typedef {ParticipantSourceTrack<
 * 	typeof ParticipantSourceType.CONFIG>} MediaStreamTrackUser
 */

/**
 * @typedef {MediaStream & {
 *  configId: number,
 * }} MediaStreamUser
 */

/**
 * @typedef {{
 *  getDeviceTrack: (
 * 		deviceId: string,
 * 		kind: ParticipantSourceMediaDeviceKind,
 *  ) => MediaStreamTrackUser | undefined,
 *  getInputDeviceFromConfigAndKind: (
 * 		configId: number,
 * 		kind: ParticipantSourceMediaDeviceKind,
 * 	) => import('../Inputs').InputDeviceInfoWithConfig | undefined,
 * 	inputDeviceStatuses: { [deviceId: string]: InputDeviceStatus },
 * 	requestInputDevice: (
 * 		deviceCfg: DeviceRequest & { physicalDeviceId: string }
 * 	) => Promise<MediaStreamTrackUser[] | undefined>,
 * 	resetInputDeviceStatusById: (deviceId: string) => void,
 * 	stopInputDevice: (deviceCfg: DeviceRequest) => void,
 * 	userActiveTracks: MediaStreamTrackUser[],
 * 	userAudioActiveTracks: MediaStreamTrackUser[],
 * 	userMediastreams: MediaStreamUser[],
 * 	userVideoActiveTracks: MediaStreamTrackUser[],
 * }} IMediaUserContext
 */

export const MediaUserContext = createContext(/** @type {IMediaUserContext} */({}));

export const useMediaUser = () => useContext(MediaUserContext);

/**
 * @typedef {{
 * 	allowAudio?: boolean,
 * 	allowVideo?: boolean,
 * 	children: React.ReactNode,
 *  resolution?: Resolution,
 * }} MediaUserProps
 */

/**
 * @typedef {{
 *  deviceId: string,
 *  kind: MediaStreamTrack['kind'],
 *  configId: number | undefined,
 *  error: Error | undefined,
 *  status: 'disabled' | 'prompt' | 'granted' | 'denied' | 'error',
 * }} InputDeviceStatus
 */

/**
 * @typedef {MediaTrackConstraints
 * 	& { deviceId: { exact: string } }} UserMediaTrackConstraints
 */

/**
 * @typedef {{
 *  virtualDeviceId: string,
 *  enabled: boolean,
 *  inputConfig: import('../Inputs').InputConfig | undefined,
 *  label: ParticipantSource['label'],
 *  physicalDeviceId: string | undefined,
 *  sourceParticipantType: typeof ParticipantSourceType.CONFIG,
 *  kind: ParticipantSourceMediaDeviceKind,
 * }} DeviceRequest
 */

/**
 * @param {MediaStreamTrack} track
 * @returns {ParticipantSourceMediaDeviceKind}
 */
const getUserDeviceKindFromTrack = (track) => {
	if (track.kind === 'audio') return 'audioinput';
	if (track.kind === 'video') return 'videoinput';
	throw new Error(`Unknown kind '${track.kind}'`);
};

/**
 *
 * @param {string} deviceId
 * @param {number} res
 * @returns {UserMediaTrackConstraints}
 */
const getUserMediaVideoConstraints = (deviceId, res) => ({
	deviceId: { exact: deviceId },
	height: { ideal: res },
	frameRate: { ideal: USER_MAX_FPS }, // max: USER_MAX_FPS (24) is not supported by Firefox
	aspectRatio: { ideal: 16 / 9 },
});

/**
 * @param {string} deviceId
 * @param {Omit<MediaTrackConstraints, 'deviceId'>} [constraints]
 * @returns {UserMediaTrackConstraints}
 */
const getUserMediaAudioConstraints = (deviceId, constraints = {}) => ({
	deviceId: { exact: deviceId },
	...DEFAULT_AUDIO_CONSTRAINTS,
	...constraints,
});

/**
 *
 * @param {number} configId
 * @param {'audioinput' | 'videoinput'} deviceKind
 * @returns {{
 * 	configId: number,
 *  deviceCfg: import('../Inputs').InputDeviceInfoWithConfig | undefined,
 *  deviceId: string | undefined,
 *  deviceTrack: MediaStreamTrackUser | undefined,
 *  error: Error | undefined,
 *  inputDeviceStatus: InputDeviceStatus | undefined,
 *  isActive: boolean,
 *  isLoading: boolean,
 *  permission: import('../Inputs').PermissionStatusState | undefined,
 *  toggleInputDevice: () => void,
 * }}
 */
export const useDeviceStatusFromConfig = (configId, deviceKind) => {
	const {
		getDeviceTrack,
		getInputDeviceFromConfigAndKind,
		inputDeviceStatuses,
		resetInputDeviceStatusById,
	} = useMediaUser();
	const {
		activateAudioInput,
		activateVideoInput,
		deactivateAudioInput,
		deactivateVideoInput,
		inputPermissions,
	} = useInputs();

	const deviceCfg = getInputDeviceFromConfigAndKind(configId, deviceKind);
	const deviceId = deviceCfg?.virtualDeviceId;
	const deviceTrack = deviceId ? getDeviceTrack(deviceId, deviceKind) : undefined;
	const inputDeviceStatus = deviceId ? inputDeviceStatuses[deviceId] : undefined;
	const isActive = !!deviceTrack;
	const permission = inputPermissions[deviceKind];

	const toggleInputDevice = () => {
		if (isActive) {
			if (deviceKind === 'audioinput') {
				deactivateAudioInput(configId);
			} else {
				deactivateVideoInput(configId);
			}
		} else {
			if (deviceId) {
				resetInputDeviceStatusById(deviceId);
			}
			if (deviceKind === 'audioinput') {
				activateAudioInput(configId);
			} else {
				activateVideoInput(configId);
			}
		}
	};

	return {
		configId,
		deviceCfg,
		deviceId,
		deviceTrack,
		error: inputDeviceStatus?.error,
		inputDeviceStatus,
		isActive: !!deviceTrack,
		isLoading: inputDeviceStatus?.status === 'prompt',
		permission,
		toggleInputDevice,
	};
};

// eslint-disable-next-line prefer-arrow-callback
export const MediaUser = memo(function MediaUser(
	/** @type {MediaUserProps} */
	{
		allowAudio = false,
		allowVideo = false,
		children,
		resolution = Resolution.P720,
	},
) {
	const {
		isDeviceUpdating,
		inputsConfigWithDefaultOverride,
		inputDevicesWithConfig,
		inputPermissions,
	} = useInputs();

	const [userAudioActiveTracks, setUserAudioActiveTracks] = useState(
		/** @type {IMediaUserContext['userAudioActiveTracks']} */([]),
	);
	const [userVideoActiveTracks, setUserVideoActiveTracks] = useState(
		/** @type {IMediaUserContext['userVideoActiveTracks']} */([]),
	);
	const userActiveTracks = useMemo(
		() => [...userAudioActiveTracks, ...userVideoActiveTracks],
		[userAudioActiveTracks, userVideoActiveTracks],
	);

	const userMediastreamsRef = useRef(
		/** @type {MediaStreamUser[]}*/([]),
	); // memoize mediastreams

	const userMediastreams = useMemo(() => {
		if (userActiveTracks.length > 0) {
			const mediaStreams = inputsConfigWithDefaultOverride
				.map((cfg) => {
					const tracks = userActiveTracks.filter((track) => track.configId === cfg.id);
					if (tracks.length <= 0) return undefined;

					const memoizedMediastream = userMediastreamsRef.current.find(
						(m) => m.configId === cfg.id,
					);
					const memoizedMediastreamTracks = memoizedMediastream?.getTracks() || [];
					if (
						tracks.length === memoizedMediastreamTracks?.length
						&& tracks.every((track) => memoizedMediastreamTracks.includes(track))
					) return memoizedMediastream; // return memoized mediastream if tracks are the same

					// Refresh mediastream when tracks change to avoid player image stuck
					const newMediaStream = /** @type {MediaStreamUser} */(new MediaStream(tracks));
					newMediaStream.configId = cfg.id;
					return newMediaStream;
				})
				// remove undefined mediastreams (if no tracks per configId)
				.filter((/** @type {MediaStreamUser | undefined} */m) => !!m);

			userMediastreamsRef.current = mediaStreams;
			return mediaStreams;
		}
		return [];
	}, [userActiveTracks, inputsConfigWithDefaultOverride]);

	const userActiveTracksRef = useRef(userActiveTracks);
	userActiveTracksRef.current = userActiveTracks;
	const unmountedRef = useRef(false);

	// cleanup
	useEffect(() => () => {
		unmountedRef.current = true;
		userActiveTracksRef.current.forEach(stopTrack);
	}, []);

	const getDeviceTrack = useCallback(
		/** @type {IMediaUserContext['getDeviceTrack']} */
		(deviceId, deviceKind) => (
			userActiveTracks?.find((track) => {
				const kind = deviceKind === 'audioinput' ? 'audio' : 'video';
				return (
					track.device.deviceId === deviceId
					&& track.kind === kind
				);
			})
		),
		[userActiveTracks],
	);

	const getInputDeviceFromConfigAndKind = useCallback(
		/** @type {IMediaUserContext['getInputDeviceFromConfigAndKind']} */
		(configId, kind) => {
			const inputDeviceWithConfig = inputDevicesWithConfig.find((deviceCfg) => (
				deviceCfg.inputConfig?.id === configId && deviceCfg.kind === kind
			));
			if (!inputDeviceWithConfig) return undefined;
			return inputDeviceWithConfig;
		},
		[inputDevicesWithConfig],
	);

	const addTrack = useCallback((
		/** @type {MediaStreamTrackUser} */track,
	) => {
		if (track.kind === 'audio') {
			setUserAudioActiveTracks((state) => [
				...(state.filter((t) => t.id !== track.id) || []),
				track,
			]);
		} else if (track.kind === 'video') {
			setUserVideoActiveTracks((state) => [
				...(state.filter((t) => t.id !== track.id) || []),
				track,
			]);
		} else {
			throw new Error(`addTrack: Unknown kind '${track.kind}'`);
		}
	}, []);

	const requestUserMedia = useCallback(
		/**
		 * @param {{
		 *  audio?: UserMediaTrackConstraints,
		 *  video?: UserMediaTrackConstraints,
		 * }} constraints
		 * @param {{
		 *  configId?: number | undefined,
		 *  virtualDeviceId: string,
		 *  label: string,
		 * }} data
		 * @returns {Promise<MediaStreamTrackUser[]>}
		 */
		async (constraints, data) => {
			const mediastream = await getUserMedia(constraints);
			if (unmountedRef.current) {
				mediastream.getTracks().forEach((track) => track.stop());
				return [];
			}
			const tracks = /** @type {MediaStreamTrackUser[]} */(mediastream.getTracks());
			tracks.forEach((track) => {
				track.configId = data?.configId;
				track.device = {
					deviceId: data.virtualDeviceId,
					kind: getUserDeviceKindFromTrack(track),
					label: data.label,
				};
				track.sourceType = ParticipantSourceType.CONFIG;
				addTrack(track);
			});
			return tracks;
		},
		[addTrack],
	);

	const [inputDeviceStatuses, setInputDeviceStatuses] = useState(
		/** @type {IMediaUserContext['inputDeviceStatuses']} */({}),
	);

	const setInputDeviceStatus = useCallback(
		/**
		 * @param {DeviceRequest} deviceCfg
		 * @param {InputDeviceStatus['status']} status
		 * @param {InputDeviceStatus['error']} [error]
		 */
		(deviceCfg, status, error) => {
			setInputDeviceStatuses((prevState) => ({
				...prevState,
				[deviceCfg.virtualDeviceId]: {
					deviceId: deviceCfg.virtualDeviceId,
					configId: deviceCfg.inputConfig?.id,
					kind: deviceCfg.kind,
					status,
					error,
				},
			}));
		},
		[],
	);

	const resetInputDeviceStatusById = useCallback(
		/** @type {IMediaUserContext['resetInputDeviceStatusById']} */
		(deviceId) => {
			setInputDeviceStatuses((prevState) => {
				const { [deviceId]: _, ...rest } = prevState;
				return rest;
			});
		},
		[],
	);

	const resolutionRef = useRef(resolution);
	resolutionRef.current = resolution;

	const requestInputDevice = useCallback(
		/** @type {IMediaUserContext['requestInputDevice']} */
		async (deviceCfg) => {
			if (deviceCfg.kind !== 'audioinput' && deviceCfg.kind !== 'videoinput') {
				throw new Error('Incorrect input devide kind');
			}

			const constraints = {};
			if (deviceCfg.kind === 'audioinput') {
				constraints.audio = getUserMediaAudioConstraints(
					deviceCfg.physicalDeviceId,
					{
						echoCancellation: { ideal: true },
						noiseSuppression: { ideal: true },
					},
				);
			}
			if (deviceCfg.kind === 'videoinput') {
				constraints.video = getUserMediaVideoConstraints(
					deviceCfg.physicalDeviceId,
					resolutionRef.current, // Use the latest resolution.
					// Dont request a new mediatrack when resolution changes.
					// We will call applyConstraints() on existing track instead.
				);
			}

			setInputDeviceStatus(deviceCfg, 'prompt');
			try {
				const tracks = await requestUserMedia(constraints, {
					configId: deviceCfg.inputConfig?.id,
					virtualDeviceId: deviceCfg.virtualDeviceId,
					label: deviceCfg.inputConfig?.label || '',
				});
				if (unmountedRef.current) {
					return undefined;
				}
				setInputDeviceStatus(deviceCfg, 'granted');
				return tracks;
			} catch (/** @type {any} */err) {
				// eslint-disable-next-line no-console
				console.error(err);
				if (rollbar) {
					rollbar.error(err, { constraints });
				}
				if (unmountedRef.current) {
					return undefined;
				}
				const error = err instanceof Error ? err : new Error('Unknown error');
				setInputDeviceStatus(deviceCfg, 'error', error);
			}
			return undefined;
		},
		[requestUserMedia, setInputDeviceStatus],
	);

	const stopInputDevice = useCallback(
		/** @type {IMediaUserContext['stopInputDevice']} */
		(deviceCfg) => {
			const tracks = userActiveTracks.filter((track) => (
				track.device.deviceId === deviceCfg.virtualDeviceId
				&& track.kind === (deviceCfg.kind === 'audioinput' ? 'audio' : 'video')
			));
			tracks.forEach((track) => {
				stopTrack(track);
				// Needs this for firefox because the event
				// "ended" is not handled when track is stopped manually
				if (track.kind === 'audio') setUserAudioActiveTracks((state) => state.filter((t) => t !== track));
				if (track.kind === 'video') setUserVideoActiveTracks((state) => state.filter((t) => t !== track));
			});
			resetInputDeviceStatusById(deviceCfg.virtualDeviceId);
		},
		[resetInputDeviceStatusById, userActiveTracks],
	);

	/**
	 * This event is used to reset the input device status
	 * after an update of the input devices.
	 * Some tracks may have been stopped because of the update,
	 * so we need to restart it
	 */
	const handleDeviceFinishUpdating = () => {
		inputDevicesWithConfig.forEach((deviceCfg) => {
			const {
				virtualDeviceId,
				kind,
			} = deviceCfg;
			if (kind !== 'audioinput' && kind !== 'videoinput') {
				return;
			}
			const inputDeviceStatus = inputDeviceStatuses[virtualDeviceId];
			const activeTrack = getDeviceTrack(virtualDeviceId, kind);
			if (!activeTrack && inputDeviceStatus?.status === 'granted') {
				resetInputDeviceStatusById(virtualDeviceId);
			}
		});
	};
	const handleDeviceFinishUpdatingRef = useRef(handleDeviceFinishUpdating);
	handleDeviceFinishUpdatingRef.current = handleDeviceFinishUpdating;

	const isDeviceUpdatingPreviousValueRef = useRef(isDeviceUpdating);

	useEffect(() => {
		const previousValue = isDeviceUpdatingPreviousValueRef.current;
		isDeviceUpdatingPreviousValueRef.current = isDeviceUpdating;
		if (!isDeviceUpdating && isDeviceUpdating !== previousValue) {
			handleDeviceFinishUpdatingRef.current();
		}
	}, [isDeviceUpdating, resetInputDeviceStatusById]);

	const { sources } = useParticipantSources();

	/**
	 * @type {DeviceRequest[]}
	 */
	const sourcesWithDeviceEnabled = useMemo(() => {
		const ss = sources
			.filter((source) => source.type === ParticipantSourceType.CONFIG)
			.reduce((acc, source) => {
				source.devices.forEach((device) => {
					const deviceCfg = inputDevicesWithConfig.find(
						(d) => d.virtualDeviceId === device.deviceId,
					);
					if (!deviceCfg) {
						return;
					}

					const dev = {
						enabled: source.type === 'config' ? deviceCfg.enabled : true,
						inputConfig: deviceCfg.inputConfig,
						kind: deviceCfg.kind,
						label: deviceCfg.label,
						physicalDeviceId: deviceCfg.physicalDeviceId,
						sourceParticipantType: source.type,
						virtualDeviceId: deviceCfg.virtualDeviceId,
					};

					acc.push(dev);
				});

				return acc;
			}, /** @type {any[]} */([]));
		return ss;
	}, [sources, inputDevicesWithConfig]);

	console.log({
		sourcesWithDeviceEnabled,
	});

	useEffect(() => {
		if (isDeviceUpdating) {
			// Don't do anything while device list is updating
			return;
		}
		sourcesWithDeviceEnabled.forEach((deviceCfg) => {
			const {
				enabled,
				inputConfig,
				kind,
				physicalDeviceId,
				virtualDeviceId,
			} = deviceCfg;

			if (kind !== 'audioinput' && kind !== 'videoinput') {
				return;
			}

			const inputPermissionGranted = inputPermissions[kind] === 'granted';
			const globalEnabled = kind === 'audioinput' ? allowAudio : allowVideo;

			const inputDeviceStatus = inputDeviceStatuses[virtualDeviceId];
			const activeTrack = getDeviceTrack(virtualDeviceId, kind);

			if (
				!enabled
				|| !globalEnabled
				|| !inputPermissionGranted
				|| !physicalDeviceId
			) {
				if (activeTrack) {
					stopInputDevice(deviceCfg);
				}
				return;
			}

			if (
				!activeTrack
				&& !inputDeviceStatus?.status
			) {
				requestInputDevice(
					// Verified above that physicalDeviceId is defined
					/** @type {DeviceRequest & { physicalDeviceId: string }} */(deviceCfg),
				);
			}
		});
	}, [
		allowAudio,
		allowVideo,
		getDeviceTrack,
		isDeviceUpdating,
		inputDeviceStatuses,
		sourcesWithDeviceEnabled,
		inputPermissions,
		requestInputDevice,
		stopInputDevice,
	]);

	useEffect(() => {
		const handleTrackEnded = (/** @type {Event}*/{ target: track }) => {
			if (!(track instanceof MediaStreamTrack)) return;
			// Commented because it cause a infinite loop if the track is stopped
			// just after being started.
			// For example on safari, the audio track is stopped when we start another
			// audio device. So the previous device track is stopped and the useEffect
			// triggers a new request for the same device. Infinitely.
			// resetInputDeviceStatusById(track.deviceId);
			track.removeEventListener('trackended', handleTrackEnded);
			if (track.kind === 'audio') setUserAudioActiveTracks((state) => state.filter((t) => t !== track));
			if (track.kind === 'video') setUserVideoActiveTracks((state) => state.filter((t) => t !== track));
		};

		userActiveTracks.forEach((track) => {
			track.addEventListener('ended', handleTrackEnded);
		});

		return () => {
			userActiveTracks.forEach((track) => {
				track.removeEventListener('ended', handleTrackEnded);
			});
		};
	}, [
		userActiveTracks,
	]);

	const value = useMemo(() => ({
		getDeviceTrack,
		getInputDeviceFromConfigAndKind,
		inputDeviceStatuses,
		requestInputDevice,
		resetInputDeviceStatusById,
		stopInputDevice,
		userActiveTracks,
		userAudioActiveTracks,
		userMediastreams,
		userVideoActiveTracks,
	}), [
		getDeviceTrack,
		getInputDeviceFromConfigAndKind,
		inputDeviceStatuses,
		requestInputDevice,
		resetInputDeviceStatusById,
		stopInputDevice,
		userActiveTracks,
		userAudioActiveTracks,
		userMediastreams,
		userVideoActiveTracks,
	]);

	return (
		<MediaUserContext.Provider value={value}>
			{children}
		</MediaUserContext.Provider>
	);
});
