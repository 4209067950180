/* eslint-disable react/prop-types */
// @ts-check
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { MediaUser } from './User';
import { MediaShareScreen } from './Share/Screen';
import { MediaShareVideo } from './Share/Video';
import { MediaShareImage } from './Share/Image';
import { MediaShareAudio } from './Share/Audio';
import { Resolution } from './utils';
import { MediaKey } from './MediaKey/MediaKey';
import { useInputs } from '../Inputs';
import { MediaCrop } from './MediaCrop/MediaCrop';
import { MediaTalkback } from './Talkback/Talkback';
import { ParticipantSourcesProvider } from '../ParticipantSources/Provider';

/** @import { MediaShareVideoType } from './utils'; */

/**
 * @typedef {{
* 	enabled: boolean;
* 	setEnabled: (enabled: React.SetStateAction<boolean>) => void;
* }} IMediaContext
*/

export const MediaContext = createContext(/** @type {IMediaContext} */({}));

export const useMedia = () => useContext(MediaContext);

/**
 * @typedef {{
 * 	children: React.ReactNode;
 * 	disabled?: boolean;
 * 	isHost?: boolean;
 * 	resolution?: Resolution;
 * }} MediaProps
 */

export const Media = (
	/** @type {MediaProps} */
	{
		children,
		disabled = false,
		isHost = false,
		resolution = Resolution.P720,
	},
) => {
	const { setEnabled: setInputsEnabled } = useInputs();
	const [enabled, setEnabled] = useState(!disabled);
	const [activeVideoTypeShare, setActiveVideoTypeShare] = useState(
		/** @type {MediaShareVideoType?} */(null),
	);

	const contextValue = useMemo(() => ({
		enabled,
		setEnabled,
	}), [enabled]);

	const previousDisabledValue = useRef(disabled);
	useEffect(() => {
		if (previousDisabledValue.current === disabled) return; // Avoid first render override
		setEnabled(!disabled);
		previousDisabledValue.current = disabled;
	}, [disabled]);

	// Sync Media.enabled and Inputs.enabled
	useEffect(() => {
		setInputsEnabled(enabled);
	}, [enabled, setInputsEnabled]);

	// Disabled input when Media is unmounted
	useEffect(() => () => {
		setInputsEnabled(false);
	}, [setInputsEnabled]);

	return (
		<ParticipantSourcesProvider>
			<MediaContext.Provider value={contextValue}>
				<MediaShareScreen
					activeShareType={activeVideoTypeShare}
					disabled={!enabled}
					isHost={isHost}
					onShare={setActiveVideoTypeShare}
					resolution={resolution}
				>
					<MediaShareVideo
						activeShareType={activeVideoTypeShare}
						disabled={!enabled}
						isHost={isHost}
						onShare={setActiveVideoTypeShare}
						resolution={resolution}
					>
						<MediaShareImage
							activeShareType={activeVideoTypeShare}
							disabled={!enabled}
							isHost={isHost}
							onShare={setActiveVideoTypeShare}
						>
							<MediaShareAudio
								disabled={!enabled}
							>
								<MediaUser
									allowAudio={enabled}
									allowVideo={enabled}
									resolution={resolution}
								>
									<MediaCrop>
										<MediaKey
											isHost={isHost}
										>
											<MediaTalkback>
												{children}
											</MediaTalkback>
										</MediaKey>
									</MediaCrop>
								</MediaUser>
							</MediaShareAudio>
						</MediaShareImage>
					</MediaShareVideo>
				</MediaShareScreen>
			</MediaContext.Provider>
		</ParticipantSourcesProvider>
	);
};
