import { v4 as uuid } from 'uuid';

const LOCAL_STORAGE_COMPUTER_ID_KEY = 'beeyou_computerId';

let computerId = localStorage.getItem(LOCAL_STORAGE_COMPUTER_ID_KEY);

if (!computerId) {
	computerId = uuid();
	localStorage.setItem(LOCAL_STORAGE_COMPUTER_ID_KEY, computerId);
}

export const getComputerId = () => computerId;
