// @ts-check

const layerIndexRegex = /(?:pip)(\d+)$/;
/**
 * @param {string} layer
 * @returns {number?}
 * */
export const getLayerIndex = (layer) => {
	if (layer === 'main') return 0;
	if (layer === 'audioShare') return 0;
	const regLayer = layerIndexRegex.exec(layer);
	return regLayer?.length === 2 ? parseInt(regLayer[1], 10) : null;
};
