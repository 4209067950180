// @ts-check

import { createContext, useContext } from 'react';

/** @import { AVAILABLE_MOBILE_CAMS } from './Inputs' */
/** @import { PermissionStatusState } from './Inputs' */

/**
 * @typedef {{
 *  kind: MediaDeviceKind,
 *  isDefault?: boolean,
 *  isFront?: boolean,
 *  isBack?: boolean,
 *  label: string,
 *  physicalDeviceId: string | undefined,
 *  virtualDeviceId: string,
 * }} VirtualDevice
 */

/**
 * @typedef {{
 * 	label: string
 * 	id: number
 * 	audioInputOff: boolean
 * 	videoInputOff: boolean
 * 	audioInputId?: string
 * 	videoInputId?: string
 * }} InputConfig
 */

/**
 * @typedef {Pick<MediaDeviceInfo, 'deviceId'
* 	| 'groupId'
* 	| 'kind'
* 	| 'label'
* > & {
* 	isDefault: boolean
* 	isFront: boolean
* 	isBack: boolean
* }} InputDeviceInfo
*/

/**
 * @typedef {VirtualDevice & {
 *  enabled: boolean,
 *  inputConfig: InputConfig | undefined,
 * }} InputDeviceInfoWithConfig
 */

/**
 * @typedef {{
 * activateAudioInput: (configId: number) => void,
 * activateVideoInput: (configId: number) => void,
 * addNewConfig: ({ label, id }: { label: string; id: number; }) => void,
 * audioInputDevices: VirtualDevice[],
 * changeAudioInputDevice: (configId: number, id: string | undefined) => void,
 * changeConfigName: (configId: number, name: string) => void,
 * changeVideoInputDevice: (
 * 	configId: number, id: string | undefined
 * ) => void,
 * currentlyEnabledDefaultDevices: VirtualDevice[],
 * deactivateAudioInput: (configId: number) => void,
 * deactivateVideoInput: (configId: number) => void,
 * defaultFaceingMode: AVAILABLE_MOBILE_CAMS,
 * deleteConfig: (configId: number) => void,
 * inputsConfig: InputConfig[],
 * inputsConfigWithDefaultOverride: InputConfig[],
 * inputDevices: VirtualDevice[],
 * inputDevicesWithConfig: InputDeviceInfoWithConfig[],
 * inputPermissions: {
 * 		audioinput: PermissionStatusState | undefined,
 * 		videoinput: PermissionStatusState | undefined,
 * },
 * isDeviceUpdating: boolean,
 * requestInputPermissions: (
 * 		permissions: { audioinput: boolean, videoinput: boolean },
 * ) => Promise<void>,
 * setDefaultFaceingMode: (TARGET: AVAILABLE_MOBILE_CAMS) => void,
 * setEnabled: (enabled: React.SetStateAction<boolean>) => void,
 * setPartialInputsConfig: (config: Partial<InputConfig>) => void,
 * setTargetCamera: (TARGET: AVAILABLE_MOBILE_CAMS) => void
 * swapFrontAndBackCamera: () => Promise<void>,
 * videoInputDevices: VirtualDevice[],
 * }} IInputsContext
 */

export const InputsContext = createContext(
	/** @type {IInputsContext | undefined} */(undefined),
);

export const useInputs = () => {
	const inputsContext = useContext(InputsContext);
	// type guard (removes undefined type)
	if (!inputsContext) {
		throw new Error('useInputs must be used within a Inputs');
	}
	return inputsContext;
};
