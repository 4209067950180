/* eslint-disable react/prop-types */
// @ts-check
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
	getSimulcastEncodings,
} from '@technomiam/soup-client';

import {
	connect,
	join as joinSoup,
	disconnect,
	soupSession,
	publishTrack,
	updateTrack,
	unpublishTrack,
} from '../../api/soup';
import { useReactVideo } from './Provider';
import { useMediaShareScreen } from '../Media/Share/Screen';
import { useMediaShareAudio } from '../Media/Share/Audio';
import { useMediaShareVideo } from '../Media/Share/Video';
import { useMediaShareImage } from '../Media/Share/Image';
import { useMediaKey } from '../Media/MediaKey/Provider';
import { usePrevious } from '../../lib/hooks';
import { KeyReplacementMode } from '../Media/MediaKey/KeyConfig';
import { useMediaTalkback } from '../Media/Talkback/Talkback';
import { getComputerId } from '../../lib/computerId';
import { ParticipantSourceType } from '../ParticipantSources/Context';

/**
 * @import { ParticipantSourceTrack } from '../ParticipantSources/Context';
 */

//TODO CHECK
const { rollbar } = window;

/**
 * @typedef {{
 * 	connectingState: string,
 * 	getIsConnected: () => boolean,
 * 	hashtag?: string,
 * 	isConnected: boolean,
 * 	join: (hashtag: string) => void,
 * 	leave: () => void,
 * 	soupId?: string,
 * }} ISoupContext
 */

export const SoupContext = createContext(/** @type {ISoupContext} */({}));

export const useSoup = () => useContext(SoupContext);

export const SOUP_CONNECTING_STATE_CONNECTED = 'SOUP_CONNECTING_STATE_CONNECTED';
export const SOUP_CONNECTING_STATE_DISCONNECTED = 'SOUP_CONNECTING_STATE_DISCONNECTED';
export const SOUP_CONNECTING_STATE_PENDING = 'SOUP_CONNECTING_STATE_PENDING';

/**
 * @typedef {{
 * 		children: React.ReactNode,
 * 		isController?: boolean,
 * }} SoupProviderProps
 */

const computerId = getComputerId();

export const SoupProvider = (
	/** @type {SoupProviderProps} */
	{
		children,
		isController = false,
	},
) => {
	const { getConnectionConfig, user } = useReactVideo();
	const [hashtag, setHashtag] = useState(/** @type {string | undefined} */(undefined));
	const [isConnected, setIsConnected] = useState(false);
	const [connectingState, setConnectingState] = useState(SOUP_CONNECTING_STATE_DISCONNECTED);
	const cancelled = useRef(false);
	const [soupId, setSoupId] = useState(/** @type {string | undefined} */(undefined));

	const getIsConnected = useCallback(() => (
		isConnected && !!soupSession()?.connected
	), [isConnected]);

	const leave = useCallback(async () => {
		setConnectingState(SOUP_CONNECTING_STATE_DISCONNECTED);
		cancelled.current = true;
		console.log('[leaveSoup]');
		disconnect();
	}, []);

	const join = useCallback(async (
		/** @type {string} */h,
	) => {
		try {
			setHashtag(h);
			cancelled.current = false;
			console.log('[joinSoup]');
			setConnectingState(SOUP_CONNECTING_STATE_PENDING);
			const connectionConfig = await getConnectionConfig();
			if (cancelled.current) return;
			await connect(connectionConfig, h);
			if (cancelled.current) return;
			await joinSoup();
			if (cancelled.current) return;
			setConnectingState(SOUP_CONNECTING_STATE_CONNECTED);
		} catch {
			setConnectingState(SOUP_CONNECTING_STATE_DISCONNECTED);
		}
	}, [getConnectionConfig]);

	const { screenshareActiveTracks } = useMediaShareScreen();
	const {
		audioshareActiveTracks,
		audiosharePreventLarsens,
	} = useMediaShareAudio();

	const { videoshareActiveTracks } = useMediaShareVideo();
	const { imageshareActiveTracks } = useMediaShareImage();
	const {
		keyOrUserActiveTracks,
		config: keyConfig,
		configOverride: keyConfigOverride,
	} = useMediaKey();
	const { recipientUsers, talkbackActiveTrack } = useMediaTalkback();

	const previousKeyConfig = usePrevious(keyConfig);
	const previousKeyConfigOverride = usePrevious(keyConfigOverride);
	const previousAudiosharePreventLarsens = usePrevious(audiosharePreventLarsens);
	const previousRecipientUsers = usePrevious(recipientUsers);

	/**
	 * @type {({
	 *  id: string,
	 *  mediaStreamTrack: MediaStreamTrack,
	 * 	isKeyTrack?: boolean,
	 * 	keyConfig?: any,
	 * 	keyConfigOverride?: any,
	 * 	preventLarsens?: boolean,
	 * 	previousPreventLarsens?: boolean,
	 *  talkback?: {
	 *  	senderUserId: string,
	 * 		receiverUserIds: string[],
	 * 	},
	 * })[]}
	 */
	const localTracks = useMemo(() => {
		const selectedTracks = keyOrUserActiveTracks
			.filter((track) => !!track) // useful ?
			// Remove tracks that are used in talkback
			.filter((track) => talkbackActiveTrack?.originalTrackId !== track.id)
			.map((mediaStreamTrack) => {
				/**
				 * @type {{
				 *  id: string,
				 * 	mediaStreamTrack: MediaStreamTrack,
				 *  isKeyTrack?: boolean,
				 *  keyConfig?: any,
				 *  keyConfigOverride?: any,
				 * }}
				 */
				const selectedTrack = { id: mediaStreamTrack.id, mediaStreamTrack };
				if (mediaStreamTrack.isKey && mediaStreamTrack.configId === 0) {
					selectedTrack.isKeyTrack = true;
					selectedTrack.keyConfig = keyConfig;
					selectedTrack.keyConfigOverride = keyConfigOverride;
				}
				return selectedTrack;
			});

		const selectedAudioshareTracks = audioshareActiveTracks
			.map((mediaStreamTrack) => {
				const preventLarsens = mediaStreamTrack.device.deviceId && audiosharePreventLarsens
					.includes(mediaStreamTrack.device.deviceId)
					? true : undefined;
				const previousPreventLarsens = mediaStreamTrack.device.deviceId
					&& previousAudiosharePreventLarsens.includes(mediaStreamTrack.device.deviceId)
					? true : undefined;
				const selectedTrack = {
					id: mediaStreamTrack.id,
					mediaStreamTrack,
					preventLarsens,
					previousPreventLarsens,
				};
				return selectedTrack;
			});

		const formattedTalkbackTrack = talkbackActiveTrack ? {
			id: talkbackActiveTrack.id,
			mediaStreamTrack: talkbackActiveTrack,
			talkback: {
				senderUserId: user.sub,
				receiverUserIds: recipientUsers.map((u) => u._id),
			},
		} : null;

		const formatTrack = (
			/** @type {MediaStreamTrack} */mediaStreamTrack,
		) => ({ id: mediaStreamTrack.id, mediaStreamTrack });

		return [
			...selectedTracks,
			...selectedAudioshareTracks,
			...(formattedTalkbackTrack ? [formattedTalkbackTrack] : []),
			...videoshareActiveTracks.map(formatTrack),
			...imageshareActiveTracks.map(formatTrack),
			...screenshareActiveTracks.map(formatTrack),
		];
	}, [
		keyConfig,
		keyConfigOverride,
		screenshareActiveTracks,
		keyOrUserActiveTracks,
		audioshareActiveTracks,
		audiosharePreventLarsens,
		previousAudiosharePreventLarsens,
		videoshareActiveTracks,
		imageshareActiveTracks,
		recipientUsers,
		talkbackActiveTrack,
		user,
	]);

	// console.log({
	// 	keyOrUserActiveTracks,
	// 	videoshareActive,
	// 	videoshareActiveTracks,
	// 	imageshareActive,
	// 	imageshareActiveTracks,
	// 	audioshareActiveTracks,
	// 	localTracks,
	// });

	useEffect(() => {
		if (connectingState === SOUP_CONNECTING_STATE_CONNECTED) {
			const soup = soupSession();
			if (!soup) {
				setIsConnected(false);
				return undefined;
			}

			const onConnected = () => {
				setIsConnected(true);
				setSoupId(soup.signaling.id);
			};
			const onDisconnected = () => { setIsConnected(false); };
			const onTransportFailed = async ({ rtcPeerConnection, transport }) => {
				// eslint-disable-next-line no-underscore-dangle
				const stats = Object.fromEntries(await transport.getStats());
				if (rollbar) {
					rollbar.error(
						'Transport connection failed.',
						{
							// eslint-disable-next-line no-underscore-dangle
							remoteSdpIceCandidates: transport._remoteSdp?._iceCandidates,
							stats,
							transport: {
								id: transport.id,
								direction: transport.direction,
							},
						},
					);
				}
				console.error('Soup transport failed', { rtcPeerConnection, transport, stats });
			};
			soup.on('connected', onConnected);
			soup.on('disconnected', onDisconnected);
			soup.on('transport:failed', onTransportFailed);

			setIsConnected(!!soup.connected);

			return () => {
				setIsConnected(false);
				soup.off('connected', onConnected);
				soup.off('disconnected', onDisconnected);
				soup.off('transport:failed', onTransportFailed);
			};
		}
		return undefined;
	}, [connectingState, user]);

	useEffect(() => {
		if (isConnected && soupSession()?.connected) {
			// console.log({ localTracks });
			const currentPublishedLocalTracksIds = Array.from(soupSession()?.productions.keys() || []);
			const localTracksIds = localTracks.map(({ id }) => id);
			const tracksToUpdate = localTracks.filter((localTrack) => (
				currentPublishedLocalTracksIds.includes(localTrack.id)
				&& (
					(
						localTrack.isKeyTrack
						&& (
							localTrack.keyConfig !== previousKeyConfig
							|| localTrack.keyConfigOverride !== previousKeyConfigOverride
						)
					)
					|| (
						localTrack.preventLarsens !== localTrack.previousPreventLarsens
					)
					|| (
						localTrack.talkback && (
							localTrack.talkback?.receiverUserIds !== previousRecipientUsers?.map((u) => u._id)
						)
					)
				)
			));
			// publish new ones
			const idsTracksToPublish = localTracksIds.filter(
				(lms) => !currentPublishedLocalTracksIds.includes(lms),
			);

			/**
			 * @param {ParticipantSourceTrack} track
			 * @returns {boolean}
			 */
			const getTrackPreventLarsens = (track) => {
				if (track.sourceType === ParticipantSourceType.VIDEOSHARE) return false;
				if (track.sourceType === ParticipantSourceType.IMAGESHARE) return false;
				if (track.sourceType === ParticipantSourceType.AUDIOSHARE) return false;
				return true;
			};

			/**
			 * @param {ParticipantSourceTrack & {
			 * 	firefoxFixCaptureSettings?: MediaTrackSettings,
			 * }} track
			 * @returns {ReturnType<typeof getSimulcastEncodings | undefined>}
			 */
			const getTrackEncodings = (track) => {
				if (track.kind !== 'video') return undefined;

				// TODO: test with max bitrate (see below)
				if (track.sourceType === ParticipantSourceType.SCREENSHARE) return undefined;

				// Firefox MediaStreamTrack.getSettings returns an empty object in case of captureStream
				// See Videoshare component for the fix
				const { height } = track.firefoxFixCaptureSettings || track.getSettings();
				const trackEncodings = getSimulcastEncodings(height);
				// TODO: test with max bitrate
				// if (trackType === TRACK_TYPE_SCREENSHARE) {
				// 	// Simulcast disabled for screensharing return only highest encoding
				// 	return trackEncodings.slice(-1);
				// }
				return trackEncodings;
			};
			console.log({ idsTracksToPublish });

			const getTrackAppDataKeyConfig = (kc) => {
				if (
					!kc
					|| kc.replacement.mode !== KeyReplacementMode.TRANSPARENT
				) return null;
				return {
					color: kc.replacement.transparentColor,
					sensitivity: kc.replacement.transparentColorSensitivity,
				};
			};

			/**
			 * @param {ParticipantSourceTrack} track
			 * @param {boolean | undefined} preventLarsens
			 * @returns {boolean}
			 */
			const getPreventLarsensOverride = (
				track,
				preventLarsens,
			) => {
				if (preventLarsens === true || preventLarsens === false) {
					return preventLarsens;
				}
				return getTrackPreventLarsens(track);
			};

			idsTracksToPublish.forEach((trackId) => {
				const localTrack = localTracks.find(({ id }) => id === trackId);
				if (!localTrack) return;
				const {
					keyConfig: trackKeyConfig,
					keyConfigOverride: trackKeyConfigOverride,
					preventLarsens,
					talkback,
				} = localTrack;
				// TODO: type localTracks
				// eslint-disable-next-line prefer-destructuring
				const mediaStreamTrack = /** @type {ParticipantSourceTrack} */(localTrack.mediaStreamTrack);

				const trackPreventLarsens = getPreventLarsensOverride(
					mediaStreamTrack,
					preventLarsens,
				);
				const trackEncodings = getTrackEncodings(mediaStreamTrack);
				const alphaColor = getTrackAppDataKeyConfig(trackKeyConfigOverride || trackKeyConfig);
				const formattedUser = {
					avatar: user.picture,
					nickname: user.nickname,
					userId: user.sub,
				};
				console.log('PUBLISH', {
					alphaColor,
					computerId,
					configId: mediaStreamTrack.configId,
					device: mediaStreamTrack.device,
					preventLarsens: trackPreventLarsens,
					talkback,
					sourceStreamType: mediaStreamTrack.sourceType,
					user: formattedUser,
					mediaStreamTrack,
				});
				publishTrack(
					mediaStreamTrack,
					{
						alphaColor,
						computerId,
						configId: mediaStreamTrack.configId,
						device: mediaStreamTrack.device,
						preventLarsens: trackPreventLarsens,
						talkback,
						sourceStreamType: mediaStreamTrack.sourceType,
						user: formattedUser,
					},
					trackEncodings,
				);
			});

			tracksToUpdate.forEach((track) => {
				const {
					keyConfig: trackKeyConfig,
					keyConfigOverride: trackKeyConfigOverride,
					preventLarsens,
					talkback,
				} = track;
				// TODO: type localTracks
				// eslint-disable-next-line prefer-destructuring
				const mediaStreamTrack = /** @type {ParticipantSourceTrack} */(track.mediaStreamTrack);
				const trackPreventLarsens = getPreventLarsensOverride(
					mediaStreamTrack,
					preventLarsens,
				);
				const alphaColor = getTrackAppDataKeyConfig(trackKeyConfigOverride || trackKeyConfig);
				console.log('UPDATE', {
					preventLarsens: trackPreventLarsens,
					alphaColor,
					talkback,
					mediaStreamTrack,
				});
				updateTrack(mediaStreamTrack, {
					preventLarsens: trackPreventLarsens,
					alphaColor,
					talkback,
				});
			});

			// unpublish old ones
			const idsTracksToUnpublish = currentPublishedLocalTracksIds.filter(
				(cplt) => !localTracksIds.includes(cplt),
			);
			// console.log({ idsTracksToUnpublish });
			idsTracksToUnpublish.forEach((id) => {
				unpublishTrack({ id });
			});
		} else if (soupSession()?.productions) {
			const currentPublishedLocalTracksIds = Array.from(soupSession()?.productions.keys() || []);
			// console.log({ currentPublishedLocalTracksIds });
			currentPublishedLocalTracksIds.forEach((id) => {
				unpublishTrack({ id });
			});
		}
	}, [
		isController,
		keyConfig,
		previousKeyConfig,
		previousKeyConfigOverride,
		isConnected,
		localTracks,
		screenshareActiveTracks,
		user,
		videoshareActiveTracks,
		imageshareActiveTracks,
		audioshareActiveTracks,
		talkbackActiveTrack,
		previousRecipientUsers,
	]);

	const contextValue = useMemo(() => ({
		connectingState,
		getIsConnected,
		hashtag,
		isConnected,
		join,
		leave,
		soupId,
	}), [
		connectingState,
		getIsConnected,
		hashtag,
		isConnected,
		join,
		leave,
		soupId,
	]);

	return (
		<SoupContext.Provider value={contextValue}>
			{children}
		</SoupContext.Provider>
	);
};
