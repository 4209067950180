/* eslint-disable react/prop-types */
// @ts-check
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import { stopTrack } from '../utils';

import { ParticipantSourceType } from '../../ParticipantSources/Context';
import { getVirtualDeviceById } from '../../Inputs/virtualDevices';

/**
 * @import {
 *  ParticipantSourceTrack,
 * } from '../../ParticipantSources/Context';
 */

/**
 * @typedef {ParticipantSourceTrack<
 * 	typeof ParticipantSourceType.AUDIOSHARE>} MediaStreamTrackAudioshare
 */

/**
 * @typedef {MediaStream} MediaStreamAudioshare
 */

/**
 * @typedef {{
 * requestAudioshare: (deviceId: string) => void,
 * audioshareActiveTracks: MediaStreamTrackAudioshare[],
 * audiosharePreventLarsens: string[],
 * audioshareRequestError: Error?,
 * setAudiosharePreventLarsens: React.Dispatch<React.SetStateAction<string[]>>,
 * setPreventLarsensForDevice: (deviceId: string, preventLarsens: boolean) => void,
 * stopAudioshare: (deviceId?: string) => void,
 * toggleAudioshare: (deviceId: string) => void,
 * }} IMediaShareAudioContext
 */

const MediaShareAudioContext = createContext(/** @type {IMediaShareAudioContext} */({}));

export const useMediaShareAudio = () => useContext(MediaShareAudioContext);

/**
 * @typedef {{
 * 		children: React.ReactNode,
 * 		disabled?: boolean,
 * }} MediaShareAudioProps
 */

export const MediaShareAudio = (
	/** @type {MediaShareAudioProps} */
	{
		children,
		disabled = false,
	},
) => {
	const [audioshareActiveTracks, setAudioshareActiveTracks] = useState(
		/** @type {IMediaShareAudioContext['audioshareActiveTracks']} */([]),
	);
	const [audioshareRequestError, setAudioshareRequestError] = useState(/** @type {Error?} */(null));
	const [audiosharePreventLarsens, setAudiosharePreventLarsens] = useState(
		/** @type {string[]} */([]),
	);

	const isAudioshareRequested = useRef(false);

	const requestAudioshare = useCallback(async (
		/** @type {string} */virtualDeviceId,
	) => {
		isAudioshareRequested.current = true;
		try {
			setAudioshareRequestError(null);

			const virtualDevice = getVirtualDeviceById(virtualDeviceId);

			if (!virtualDevice) {
				throw new Error(`Virtual device not found for audio device ${virtualDeviceId}`);
			}

			const mediastream = await navigator.mediaDevices.getUserMedia({
				video: false,
				audio: {
					deviceId: virtualDevice.physicalDeviceId,
					autoGainControl: false,
					channelCount: 2,
					echoCancellation: false,
					latency: 0,
					noiseSuppression: false,
					sampleRate: 48000,
					sampleSize: 16,
					volume: 1.0,
				},
			});

			// Allow cancellation
			const isStillAudioshareRequested = isAudioshareRequested.current;

			// Possible cancellation while getUserMedia was pending
			const tracks = /** @type {MediaStreamTrackAudioshare[]} */(mediastream.getTracks());
			if (!isStillAudioshareRequested) {
				tracks.forEach((track) => track.stop());
			} else {
				tracks.forEach((track) => {
					track.device = {
						deviceId: virtualDeviceId,
						kind: 'audioinput',
						label: `Screenshare ${track.kind}`,
					};
					track.sourceType = ParticipantSourceType.AUDIOSHARE;
				});
				setAudioshareActiveTracks((state) => [...state, ...tracks]);
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
			setAudioshareRequestError(/** @type {Error}*/ (error));
		}
	}, []);

	useEffect(() => {
		/** @param {Event} event */
		const handleTrackEnded = ({ target: track }) => {
			if (!track) return;

			track.removeEventListener('trackended', handleTrackEnded);
			setAudioshareActiveTracks((state) => state.filter((t) => t !== track));
		};

		audioshareActiveTracks.forEach((track) => {
			track.addEventListener('ended', handleTrackEnded);
		});

		return () => {
			audioshareActiveTracks.forEach((track) => {
				track.removeEventListener('ended', handleTrackEnded);
			});
		};
	}, [
		audioshareActiveTracks,
	]);

	const stopAudioshare = useCallback((
		/** @type {string | undefined} */virtualDeviceId,
	) => {
		isAudioshareRequested.current = false;
		if (virtualDeviceId) {
			const tracks = audioshareActiveTracks.filter(
				(track) => track.device.deviceId === virtualDeviceId,
			);
			tracks.forEach(stopTrack);
			setAudioshareActiveTracks(
				(state) => state.filter((t) => t.device.deviceId !== virtualDeviceId),
			);
		} else {
			audioshareActiveTracks.forEach(stopTrack);
			setAudioshareActiveTracks((s) => (s.length < 1 ? s : []));
		}
		setAudioshareRequestError(null);
	}, [
		audioshareActiveTracks,
	]);

	useEffect(() => {
		// If audioshare is stopped from system button, disable audioshare
		if (audioshareActiveTracks.length < 1) stopAudioshare();
	}, [audioshareActiveTracks, stopAudioshare]);

	useEffect(() => {
		const shouldStopAudioshare = isAudioshareRequested.current && disabled;
		if (shouldStopAudioshare) stopAudioshare();
	}, [disabled, stopAudioshare]);

	const audioshareActiveTracksRef = useRef(audioshareActiveTracks);

	useEffect(() => {
		audioshareActiveTracksRef.current = audioshareActiveTracks;
	}, [audioshareActiveTracks]);

	// cleanup
	useEffect(() => () => {
		isAudioshareRequested.current = false;
		audioshareActiveTracksRef.current.forEach(stopTrack);
	}, []);

	const toggleAudioshare = useCallback((
		/** @type {string} */virtualDeviceId,
	) => {
		if (!virtualDeviceId) {
			throw new Error('Please provide a virtualDeviceId');
		}
		const track = audioshareActiveTracks.find((t) => t.device.deviceId === virtualDeviceId);
		if (track) stopAudioshare(virtualDeviceId);
		else requestAudioshare(virtualDeviceId);
	}, [
		audioshareActiveTracks,
		requestAudioshare,
		stopAudioshare,
	]);

	const setPreventLarsensForDevice = useCallback((
		/** @type {string} */virtualDeviceId,
		/** @type {boolean} */preventLarsens,
	) => {
		setAudiosharePreventLarsens((state) => {
			if (preventLarsens && !state.includes(virtualDeviceId)) {
				return [...state, virtualDeviceId];
			}
			if (!preventLarsens && state.includes(virtualDeviceId)) {
				return state.filter((d) => d !== virtualDeviceId);
			}
			return state;
		});
	}, []);

	const value = useMemo(() => ({
		requestAudioshare,
		audioshareActiveTracks,
		audiosharePreventLarsens,
		audioshareRequestError,
		setAudiosharePreventLarsens,
		setPreventLarsensForDevice,
		stopAudioshare,
		toggleAudioshare,
	}), [
		requestAudioshare,
		audioshareActiveTracks,
		audiosharePreventLarsens,
		audioshareRequestError,
		setAudiosharePreventLarsens,
		setPreventLarsensForDevice,
		stopAudioshare,
		toggleAudioshare,
	]);

	return (
		<MediaShareAudioContext.Provider value={value}>
			{children}
		</MediaShareAudioContext.Provider>
	);
};
