/* eslint-disable react/prop-types */
// @ts-check
import { createContext, useContext, useMemo, useState } from 'react';
import { useTalkbackReceiver } from '../../lib/talkback';

/**
 * @typedef {{
 * 	isDisabled: boolean,
 * 	isMuted: boolean,
 *  setIsMuted: React.Dispatch<React.SetStateAction<boolean>>,
 * }} ILiveVolumeContext
 */

export const LiveVolumeContext = createContext(
	/** @type {ILiveVolumeContext | undefined} */(undefined),
);

export const usePlayerLiveVolume = () => {
	const liveVolumeContext = useContext(LiveVolumeContext);
	// type guard (removes undefined type)
	if (!liveVolumeContext) {
		throw new Error('usePlayerLiveVolume must be used within a PlayerLiveVolumeProvider');
	}
	return liveVolumeContext;
};

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * }} PlayerLiveVolumeProviderProps
 */

export const PlayerLiveVolumeProvider = (
	/** @type {PlayerLiveVolumeProviderProps} */
	{
		children,
	},
) => {
	const [isMuted, setIsMuted] = useState(false);

	const { talkbackReceivingSources } = useTalkbackReceiver();
	const isTalkbackReceiving = talkbackReceivingSources.length > 0;

	const contextValue = useMemo(() => ({
		isDisabled: isTalkbackReceiving,
		isMuted: isMuted || isTalkbackReceiving,
		setIsMuted,
	}), [
		isMuted,
		isTalkbackReceiving,
		setIsMuted,
	]);

	return (
		<LiveVolumeContext.Provider value={contextValue}>
			{children}
		</LiveVolumeContext.Provider>
	);
};
