// @ts-check
/* eslint-disable react/prop-types */
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ParticipantSourcesContext, ParticipantSourceType } from './Context';
import { useInputs } from '../Inputs';
import { getComputerId } from '../../lib/computerId';
import { getVirtualDeviceById } from '../Inputs/virtualDevices';

/**
 * @import { IParticipantSourcesContext, ParticipantSourceDeviceKind } from './Context';
 */

/**
 * @typedef {{
 *  children: import('react').ReactNode
 * }} ParticipantSourcesProviderProps
 */

export const ParticipantSourcesProvider = (
	/** @type {ParticipantSourcesProviderProps} */
	{ children },
) => {
	const [sources, setSources] = useState(
		/** @type {IParticipantSourcesContext['sources']} */([]),
	);

	const removeSourceById = useCallback(
		/** @type {IParticipantSourcesContext['removeSourceById']} */
		(sourceId) => {
			setSources((prev) => prev.filter(({ id }) => id !== sourceId));
		}, [],
	);

	const removeSourceByType = useCallback(
		/** @type {IParticipantSourcesContext['removeSourceById']} */
		(sourceType) => {
			setSources((prev) => prev.filter(({ type }) => type !== sourceType));
		}, [],
	);

	const createOrUpdateSource = useCallback(
		/** @type {IParticipantSourcesContext['createOrUpdateSource']} */
		(source) => {
			setSources((prev) => {
				const src = prev.find(({ id }) => id === source.id);
				if (!src) {
					return [...prev, source];
				}
				return prev.map((s) => (s.id === source.id ? {
					...s,
					...source,
				} : s));
			});
		}, [],
	);

	const {
		inputDevices,
		inputsConfigWithDefaultOverride,
	} = useInputs();

	useEffect(() => {
		// TODO: better setState to avoid re-renders
		removeSourceByType(ParticipantSourceType.CONFIG);
		inputsConfigWithDefaultOverride.forEach((inputConfig) => {
			const devices = [
				(inputConfig.audioInputId && inputConfig.audioInputId !== 'none')
					? {
						deviceId: inputConfig.audioInputId,
						kind: /** @type {ParticipantSourceDeviceKind} */('audioinput'),
						label: getVirtualDeviceById(inputConfig.audioInputId)?.label || '',
					}
					: undefined,
				(inputConfig.videoInputId && inputConfig.videoInputId !== 'none')
					? {
						deviceId: inputConfig.videoInputId,
						kind: /** @type {ParticipantSourceDeviceKind} */('videoinput'),
						label: getVirtualDeviceById(inputConfig.videoInputId)?.label || '',
					}
					: undefined,
			].filter((device) => !!device);

			const id = `${getComputerId()}:${inputConfig.id}:${ParticipantSourceType.CONFIG}`;

			const source = {
				configId: inputConfig.id,
				id,
				label: inputConfig.label,
				type: ParticipantSourceType.CONFIG,
				devices,
			};

			createOrUpdateSource(source);
		});
	}, [
		createOrUpdateSource,
		inputsConfigWithDefaultOverride,
		inputDevices,
		removeSourceByType,
	]);

	const value = useMemo(() => ({
		createOrUpdateSource,
		removeSourceById,
		sources,
	}), [
		createOrUpdateSource,
		removeSourceById,
		sources,
	]);

	console.log({
		ParticipantSourcesProvider: value,
	});

	return (
		<ParticipantSourcesContext.Provider value={value}>
			{children}
		</ParticipantSourcesContext.Provider>
	);
};
