// @ts-check

import { createContext, useContext, useEffect, useRef, useState } from 'react';

import { soupSession } from '../../api/soup';

/**
 * @import { SoupSession } from '@technomiam/soup-client';
 * @import { STREAM_TYPE_LIVE, STREAM_TYPE_PREVIEW } from '../../lib/stream';
 * @import { ParticipantSourceDevice } from '../ParticipantSources/Context';
 */

/**
 * @typedef {{
 * 	configId?: number,
 * 	computerId?: string,
 *	device?: ParticipantSourceDevice,
 *	streamType: STREAM_TYPE_LIVE | STREAM_TYPE_PREVIEW,
 *	peerExceptions?: string[],
 *	peerReceiver?: string,
 *	alphaColor?: {
 *		color: {
 *			r: number,
 *			g: number,
 *			b: number,
 *		},
 *		sensitivity: number,
 *	}?,
 *	preventLarsens?: boolean,
 *	talkback?: { senderUserId: string, receiverUserIds: string[] }
 *	user: {
 *		avatar?: string,
 *		nickname: string,
 *		userId: string
 *	},
 * }} AppData
 */

/**
 * Represents a published media (video or audio) with its metadata.
 *
 * @typedef {{
 * 	appData: AppData,
 * 	hashtag: string,
 * 	kind: MediaStreamTrack['kind'],
 * 	peerId: string,
 * 	producerId: string,
 * }} Publication
 */

/**
 * @typedef {{
 *	alphaColor?: {
 * 		color: {
 * 			r: number,
 * 			g: number,
 * 			b: number,
 * 		},
 * 		sensitivity: number,
 * 	}?,
 *  computerId: string,
 *  configId?: number,
 *  device: ParticipantSourceDevice,
 *	preventLarsens?: boolean,
 *  sourceStreamType: string,
 *	talkback?: { senderUserId: string, receiverUserIds: string[] }
 *	user: {
 *  	avatar?: string,
 *  	nickname: string,
 *  	userId: string,
 *  },
 * }} PublishAppData
 */
/**
* Represents a subscribed publication, with the media stream track available for consumption.
*
* @typedef {Publication & {
* 	mediaStreamTrack: MediaStreamTrack
* }} Subscription
*/

/**
 * @typedef {{
 * 	spatialLayer: number,
 * }} PreferredLayers
 */

/**
 * @typedef {{
 *  publication: Pick<Publication, 'producerId'>,
 *  preferredLayers?: PreferredLayers,
 * }} SubscriptionRequest
 */

/** @enum {string} */
export const SoupStatus = {
	CONNECTED: 'CONNECTED',
	DISCONNECTED: 'DISCONNECTED',
};

/**
 * @typedef {{
 *  publications: Publication[],
 *  soupSession: SoupSession | null,
 *  status: SoupStatus,
 * }} ISoupContext
 */

export const SoupContext = createContext(
	/** @type {ISoupContext | undefined} */(undefined),
);

/**
 * @returns {ISoupContext}
 */
export const useSoup = () => {
	const sourcesContext = useContext(SoupContext);
	// type guard (removes undefined type)
	if (!sourcesContext) {
		throw new Error('useSoup must be used within a Soup');
	}
	return sourcesContext;
};

/**
 * @return {SoupSession | null}
 */
export const useSoupSession = () => {
	const [session, setSession] = useState(soupSession());

	const sessionRef = useRef(session);
	sessionRef.current = session;

	useEffect(() => {
		const loop = setInterval(() => {
			const currentSession = soupSession();
			if (currentSession === sessionRef.current) return;
			setSession(currentSession);
		}, 1000);

		return () => clearInterval(loop);
	}, []);

	return session;
};
